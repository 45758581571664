import React from 'react';
import { Break, Text, Image } from './RichTextPreviewer.elements';

const RichTextPreviewer = props => {
  const { content } = props;

  return (
    <div dir="rtl" className="urduText font-bold text-xl leading-[36px]">
      {content.map((item, index) => {
        if (item.type === 'paragraph' && item.children[0].text)
          return (
            <Text className="urduText" key={index}>
              {item.children[0].text}
            </Text>
          );

        if (item.type === 'paragraph' && !item.children[0].text) return <Break key={index} />;

        if (item.type === 'image') return <Image key={index} url={item.url} />;
      })}
    </div>
  );
};

export default RichTextPreviewer;
