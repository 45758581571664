import React, { useEffect, useState } from 'react';
import Header from '../../../constant/Header';
import Footer from '../../../constant/footer';
import ScrollToTop from '../../../constant/ScrollToTop';
import Pagination from '../../../constant/pagination';
import { useDispatch, useSelector } from 'react-redux';
import { GetArticleAction } from '../../../module/action/Articles';
import Spinner from '../../../constant/Spinner';
import { assetURL } from '../../../api/utils';
import { downloadFile } from '../../../module/ConstantFunctions';
import ButtonSpinner from '../../../constant/ButtonSpinner';

const ArtilceRow = ({ value, isOdd }) => {
  const buttonClass =
    'flex items-center justify-center h-10 px-6 py-1 bg-[#fff] hover:bg-[#0E3A1E] text-base md:text-lg text-[#0E3A1E] hover:text-[#fff] font-bold border-[1px] border-[#0E3A1E] rounded-xl ease-in duration-300 disabled:opacity-[0.4] disabled:cursor-not-allowed';
  const [downloadLoader, setDownloadLoader] = useState(false);

  const downloadHandler = () =>
    downloadFile(
      value.pdf_file_url ? value.pdf_file_url : `${assetURL}${value.pdf_file}`,
      value.pdf_file,
      'application/pdf',
      setDownloadLoader
    );

  const wrapperClassName = `w-full md:container mb-5 mx-auto text-right rounded-xl border p-4 md:p-8 ${
    isOdd ? 'bg-[#513a3a]' : 'border-green-900 bg-green-50'
  }`;

  return (
    <div className={wrapperClassName}>
      <div className="text-2xl font-bold flex flex-col-reverse sm:flex-row justify-between sm:items-center">
        <div className="flex items-center gap-2">
          <button //
            disabled={downloadLoader}
            className={buttonClass}
            onClick={downloadHandler}>
            {downloadLoader ? <ButtonSpinner color="#0E3A1E" /> : 'دانلود'}
          </button>

          <a //
            className={buttonClass}
            href={value.pdf_file_url || `${assetURL}${value.pdf_file}`}
            target="_blank"
            rel="noopener noreferrer">
            باز کن
          </a>
        </div>
        <p className="text-right leading-10" dir="rtl">
          <span className="cursor-pointer hover:text-themeColor">{value.titleName}</span>
        </p>
      </div>
    </div>
  );
};

function Articles() {
  const dispatch = useDispatch();
  const ArticlesData = useSelector(e => e.Articles.ArticlesData);
  const ArticlesCount = useSelector(e => e.Articles.ArticlesCount);
  const getArticleLoader = useSelector(e => e.Articles.getArticleLoader);

  const [articleArr, setArticleArr] = useState([]);

  const [page, setPage] = useState(0);
  const limit = 8;

  useEffect(() => {
    dispatch(GetArticleAction(page, limit));
  }, [page]);

  useEffect(() => {
    if (ArticlesData) setArticleArr(ArticlesData);
  }, [ArticlesData]);

  return (
    <div className="relative h-min-100">
      {/* header */}
      <Header page={0} limit={2} />
      {/* Daily hadith */}

      <div className="container  mx-auto p-8 mt-10 md:px-0 ">
        <div className="flex justify-end mb-10">
          <p className="text-themeColor text-right text-4xl font-bold">مقالات</p>
        </div>
        {/* list */}
        {getArticleLoader ? (
          <Spinner />
        ) : (
          articleArr.map((value, index) => (
            <ArtilceRow //
              key={index}
              value={value}
              isOdd={index % 2}
            />
          ))
        )}
        <Pagination page={page} setPage={setPage} limit={limit} count={ArticlesCount} />
      </div>
      {/* footer */}
      <ScrollToTop />
      <Footer />
    </div>
  );
}

export default Articles;
