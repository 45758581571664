import React from 'react';

import RightArrow from '../../../../../assests/images/RightArrow.png';
import LeftArrow from '../../../../../assests/images/LeftArrowWhite.png';
import Tooltip from '../../../../../constant/Tooltip';

const HadithHeader = props => {
  const { activeAnwan, postDetail, next, navFunction, nextFunction, previous, previousFunction } = props;

  const [leftHover, setleftHover] = React.useState(false);
  const [rightHover, setrightHover] = React.useState(false);

  return (
    <div className={`flex justify-between w-full md:w-3/6 mx-auto mb-10`}>
      <button
        onMouseOver={() => setleftHover(true)}
        onMouseOut={() => setleftHover(false)}
        onClick={nextFunction}
        disabled={!next}
        className={`shrink-0 relative w-16 h-16 flex ease-in duration-300 justify-center items-center md:p-4 border-[#0E3A1E3D] border-[2px] rounded-full hover:bg-[#2A9134] disabled:cursor-not-allowed`}>
        {leftHover && <Tooltip text={'بعد'} />}
        {leftHover ? <img src={LeftArrow} /> : <img src={RightArrow} className="rotate-180" />}
      </button>

      <div className="text-xl md:text-3xl text-themeColorDark text-center font-bold">
        <p className="px-2">{activeAnwan}</p>
        <p>({postDetail.post_number})</p>
      </div>

      <button
        onMouseOver={() => setrightHover(true)}
        onMouseOut={() => setrightHover(false)}
        disabled={!previous}
        onClick={previousFunction}
        className={`shrink-0 hover:bg-[#2A9134] relative w-16 h-16 flex justify-center ease-in duration-300 items-center p-4 border-[#0E3A1E3D] border-[2px] rounded-full disabled:cursor-not-allowed`}>
        {rightHover && <Tooltip text={'قبلی'} />}
        {rightHover ? <img src={LeftArrow} className="rotate-180" /> : <img src={RightArrow} />}
      </button>
    </div>
  );
};

export default HadithHeader;
