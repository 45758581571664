import React, { useEffect, useState } from 'react';
import Header from '../../../../constant/Header';
import Footer from '../../../../constant/footer';

function ContactUs() {
  return (
    <div className="relative h-min-100">
      {/* header */}
      <Header page={0} limit={2} />
      {/* Daily hadith */}

      <div className="container mx-auto p-8 mt-10 md:px-0 ">
        <div className="flex justify-end mb-10">
          <p className="text-themeColor text-right text-4xl font-bold">تماس با ما</p>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default ContactUs;
