import './App.css';
import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { Routes, Route } from 'react-router';
import Home from './features/home/Home';
import Hadith from './features/hadith/pages/hadith';
import Categories from './features/categories/categories';
import Articles from './features/articles/articles';
import Books from './features/books/Books';
import SingleBook from './features/books/SingleBook';
import HadithDetail from './features/hadith/pages/HadithDetail';
import MostViewed from './features/hadith/pages/MostViewed';
import Chapters from './features/categories/Chapters';
import { useDispatch } from 'react-redux';
import { hadithOfTheDayAction } from './module/action/Hadith';
// import HadithOfTheDay from './features/hadith/pages/HadithOfTheDay';
// import MostFavourite from './features/hadith/pages/MostFavourite';
import SearchHadith from './features/hadith/pages/SearchHadith';
// import SearchResult from './features/hadith/pages/SearchResult';
import OurIdentity from './features/info/pages/our-identity';
import AboutUs from './features/info/pages/about-us';
import CommentsAndSuggestions from './features/info/pages/comments-and-suggestions';
import Characteristic from './features/info/pages/characteristic';
import TermsAndConditions from './features/info/pages/terms-and-conditions';
import ContactUs from './features/info/pages/contact-us';

function App() {
  const dispatch = useDispatch();
  React.useEffect(() => {
    dispatch(hadithOfTheDayAction(new Date()));
  }, []);

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" Component={Home} />
        <Route path="/hadith" Component={Hadith} />

        <Route path="/categories" Component={Categories} />
        <Route path="/articles" Component={Articles} />
        <Route path="/Books" Component={Books} />
        <Route path="/SingleBook" Component={SingleBook} />
        <Route path="/HadithDetail" Component={HadithDetail} />
        <Route path="/MostViewed" Component={MostViewed} />
        <Route path="/SearchHadith" Component={SearchHadith} />
        {/* <Route path="/MostFavourite" Component={MostFavourite} />  */}
        {/* <Route path="/hadithOfTheDay" Component={HadithOfTheDay} /> */}
        {/* <Route path="/SearchResult" Component={SearchResult} /> */}
        <Route path="/Chapters" Component={Chapters} />

        <Route path="/our-identity" Component={OurIdentity} />
        <Route path="/about-us" Component={AboutUs} />
        <Route path="/comments-and-suggestions" Component={CommentsAndSuggestions} />
        <Route path="/characteristic" Component={Characteristic} />
        <Route path="/terms-and-conditions" Component={TermsAndConditions} />
        <Route path="/contact-us" Component={ContactUs} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
