import React, { useEffect, useState } from 'react';
import Header from '../../../constant/Header';
import HadithPreviewCard from '../../hadith/components/HadithPreviewCard';
import Footer from '../../../constant/footer';
import { useNavigate } from 'react-router-dom';
import ScrollToTop from '../../../constant/ScrollToTop';
import { useDispatch, useSelector } from 'react-redux';
import { getAllPostAction } from '../../../module/action/Post';
import { getPersianDayOfWeek, persianDate } from '../../../module/ConstantFunctions';
import { mostViewedHadithAction } from '../../../module/action/Hadith';
import Spinner from '../../../constant/Spinner';

function Home() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const mostViewedHadithData = useSelector(e => e.Hadith.mostViewedHadithData);
  const hadithOfTheDayData = useSelector(e => e.Hadith.hadithOfTheDayData);
  const mostViewedHadithLoader = useSelector(e => e.Hadith.mostViewedHadithLoader);
  const hadithOfTheDayLoader = useSelector(e => e.Hadith.hadithOfTheDayLoader);

  const [postArr, setPostArr] = useState([]);
  const [hadithDay, setHadithDay] = useState(false);

  useEffect(() => {
    dispatch(mostViewedHadithAction(0, 4));
  }, []);

  useEffect(() => {
    if (hadithOfTheDayData.length) {
      setHadithDay(hadithOfTheDayData[0]);
    }
  }, [hadithOfTheDayData]);

  useEffect(() => {
    if (mostViewedHadithData) {
      setPostArr(mostViewedHadithData);
    }
  }, [mostViewedHadithData]);

  return (
    <div className="relative h-min-100">
      {/* header */}
      <Header page={0} limit={2} heading />
      {/* Daily hadith */}

      {mostViewedHadithLoader && hadithOfTheDayLoader ? (
        <Spinner />
      ) : (
        <div className="container mx-auto p-4 md:px-0">
          {hadithDay ? (
            <>
              <div className="mt-10 mb-6 flex justify-between">
                <p
                  onClick={() => navigate('/hadith')}
                  className=" mt-2.5 underline underline-offset-4 text-base font-bold cursor-pointer">
                  أحادیث روز
                </p>
                <div>
                  <p className="text-themeColor text-right text-4xl font-bold">حدیث روز</p>
                  <p className="text-themeColorDark mt-4 text-right text-[22px] font-bold mt-2">
                    {getPersianDayOfWeek(hadithDay.hadith_date_post)} {persianDate(hadithDay.hadith_date_post)}
                  </p>
                </div>
              </div>
              <HadithPreviewCard
                background
                data={hadithDay}
                shareUrl={
                  'https://mail.google.com/mail/u/0/?tf=cm' +
                  '&body=' +
                  `https%3A%2F%2Feastgaheazadi.com%2FHadithDetail%3FpostNumber%3D${hadithDay.post_number?.trim()}%26postId%3D${
                    hadithDay._id
                  }`
                }
                onClick={e => {
                  let redirectUrl = window.location.pathname;
                  navigate(
                    `/hadithOfTheDay?postNumber=${hadithDay.post_number?.trim()}&postId=${
                      hadithDay._id
                    }$redirectUrl=${redirectUrl}`
                  );
                }}
              />
            </>
          ) : null}
          {postArr.length ? (
            <>
              {/* most viewed hadith */}
              <div className="mb-10 mt-10 flex justify-between">
                <p
                  onClick={() => navigate('/MostViewed')}
                  className="mt-2.5 underline underline-offset-4 text-base font-bold cursor-pointer">
                  پربیننده ترین احادیث
                </p>
                <div>
                  <p className="text-themeColor text-right text-4xl font-bold">پربیننده ترین حدیث</p>
                </div>
              </div>
              {postArr.map((a, i) => (
                <HadithPreviewCard
                  data={a}
                  index={i}
                  shareUrl={
                    'https://mail.google.com/mail/u/0/?tf=cm' +
                    '&body=' +
                    `https%3A%2F%2Feastgaheazadi.com%2FHadithDetail%3FpostNumber%3D${a.post_number?.trim()}%26postId%3D${
                      a._id
                    }`
                  }
                  onClick={e => {
                    let redirectUrl = window.location.pathname;
                    navigate(
                      `/MostFavourite?postNumber=${a.post_number?.trim()}&postId=${a._id}&redirectUrl=${redirectUrl}`
                    );
                  }}
                />
              ))}
            </>
          ) : null}
        </div>
      )}
      <ScrollToTop />
      <Footer />
    </div>
  );
}

export default Home;
