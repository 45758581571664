import React, { useEffect, useState } from 'react';
import Header from '../../../../constant/Header';
import HadithPreviewCard from '../../components/HadithPreviewCard';
import Footer from '../../../../constant/footer';
import ScrollToTop from '../../../../constant/ScrollToTop';
import Pagination from '../../../../constant/pagination';
import { useDispatch, useSelector } from 'react-redux';
import { previousHadithOfTheDayAction } from '../../../../module/action/Hadith';
import { getPersianDayOfWeek, persianDate } from '../../../../module/ConstantFunctions';
import Spinner from '../../../../constant/Spinner';
import { useNavigate } from 'react-router';
function Hadith() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const previousHadithOfTheDayData = useSelector(e => e.Hadith.previousHadithOfTheDayData);
  const previousHadithCount = useSelector(e => e.Hadith.previousHadithCount);
  const previousHadithOfTheDayLoader = useSelector(e => e.Hadith.previousHadithOfTheDayLoader);

  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(4);
  useEffect(() => {
    dispatch(previousHadithOfTheDayAction(page, limit));
  }, [page, limit]);

  return (
    <div className="relative h-min-100">
      {/* header */}
      <Header page={0} limit={2} />
      {/* Daily hadith */}
      <div className="container mx-auto px-4 md:px-0">
        <div className="mt-8 flex justify-between">
          <p className="underline underline-offset-8 text-base font-bold">{/* أحادیث روز */}</p>
          <div>
            <p className="text-themeColor text-right text-4xl font-bold">احادیث روز</p>
          </div>
        </div>

        {/* list */}
        {previousHadithOfTheDayLoader ? (
          <Spinner />
        ) : (
          previousHadithOfTheDayData &&
          previousHadithOfTheDayData.map((a, i) => (
            <>
              <p key={i} className="text-themeColorDark text-right text-[22px] font-bold mt-2 mb-6">
                {getPersianDayOfWeek(a.hadith_date_post)} {persianDate(a.hadith_date_post)}
              </p>
              <HadithPreviewCard
                data={a}
                shareUrl={
                  'https://mail.google.com/mail/u/0/?tf=cm' +
                  '&body=' +
                  `https://eastgaheazadi.com/HadithDetail?postNumber=${a.post_number?.trim()}&postId=${a._id}`
                }
                onClick={e => {
                  let redirectUrl = window.location.pathname;
                  navigate(
                    `/hadithOfTheDay?postNumber=${a.post_number?.trim()}&postId=${a._id}&redirectUrl=${redirectUrl}`
                  );
                }}
              />
            </>
          ))
        )}
        <Pagination page={page} setPage={setPage} limit={limit} count={previousHadithCount} />
      </div>
      <ScrollToTop />
      <Footer />
    </div>
  );
}

export default Hadith;
