import React, { useEffect, useState } from 'react';
import DownloadWhite from '../../../assests/images/downloadWhite.svg';
import Header from '../../../constant/Header';
import Footer from '../../../constant/footer';
import { useNavigate } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import ScrollToTop from '../../../constant/ScrollToTop';
import { getBookVolumesAction, getSingleBookAction } from '../../../module/action/Book';
import { downloadFile } from '../../../module/ConstantFunctions';
import Spinner from '../../../constant/Spinner';
import Tooltip from '../../../constant/Tooltip';
import { assetURL } from '../../../api/utils';
import ButtonSpinner from '../../../constant/ButtonSpinner';

const Volume = ({ val, i, bookData }) => {
  const [DownloadHover, setDownloadHover] = useState(false);
  let [downloadLoader, setDownloadLoader] = useState(false);

  return (
    <div className="w-full flex justify-between bg-[#EEF2F0] px-4 pr-8 md:px-8 py-4 rounded-md mb-2" dir="rtl" key={i}>
      <p className="text-xl font-bold">{val.volume_name}</p>
      <p className="w-36 md:w-48 text-xl font-bold flex items-center">
        <button
          disabled={downloadLoader}
          onMouseOver={() => setDownloadHover(i)}
          onMouseOut={() => setDownloadHover(false)}
          className="relative hover:bg-[#0E3A1E] ml-2 rounded-full bg-[#DEEAE1] p-2"
          onClick={() => {
            downloadFile(
              val.pdf_file_url ? val.pdf_file_url : `${assetURL}${val.pdf_file}`,
              `${bookData.bookName}-${val.volume_name}`,
              'application/pdf',
              setDownloadLoader
            );
          }}>
          {downloadLoader ? <ButtonSpinner color={'#0E3A1E'} /> : null}
          <span
            className={
              downloadLoader ? `opacity-0 flex items-center justify-center` : 'flex items-center justify-center'
            }>
            {DownloadHover === i && <Tooltip text={'دانلود'} />}
            {DownloadHover === i ? (
              <img src={DownloadWhite} width={'20px'} />
            ) : (
              // <img src={Download} width={'24px'} />
              <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M15 7.50008H13.3333C12.8333 7.50008 12.5 7.83341 12.5 8.33341C12.5 8.83341 12.8333 9.16675 13.3333 9.16675H15C15.5 9.16675 15.8333 9.50008 15.8333 10.0001V15.8334C15.8333 16.3334 15.5 16.6667 15 16.6667H5C4.5 16.6667 4.16667 16.3334 4.16667 15.8334V10.0001C4.16667 9.50008 4.5 9.16675 5 9.16675H6.66667C7.16667 9.16675 7.5 8.83341 7.5 8.33341C7.5 7.83341 7.16667 7.50008 6.66667 7.50008H5C3.58333 7.50008 2.5 8.58341 2.5 10.0001V15.8334C2.5 17.2501 3.58333 18.3334 5 18.3334H15C16.4167 18.3334 17.5 17.2501 17.5 15.8334V10.0001C17.5 8.58341 16.4167 7.50008 15 7.50008ZM6.91667 12.2501L9.41667 14.7501C9.58333 14.9167 9.75 15.0001 10 15.0001C10.25 15.0001 10.4167 14.9167 10.5833 14.7501L13.0833 12.2501C13.4167 11.9167 13.4167 11.4167 13.0833 11.0834C12.75 10.7501 12.25 10.7501 11.9167 11.0834L10.8333 12.1667V2.50008C10.8333 2.00008 10.5 1.66675 10 1.66675C9.5 1.66675 9.16667 2.00008 9.16667 2.50008V12.1667L8.08333 11.0834C7.75 10.7501 7.25 10.7501 6.91667 11.0834C6.58333 11.4167 6.58333 11.9167 6.91667 12.2501Z"
                  fill="#2A9134"
                />
              </svg>
            )}
          </span>
          {/* <img src={Download} /> */}
        </button>
        دانلود جلد کامل
      </p>
    </div>
  );
};

function SingleBook() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const volumesData = useSelector(e => e.Book.volumesData);
  const getSingleBook = useSelector(e => e.Book.getSingleBook);
  const getBookVolumesLoader = useSelector(e => e.Book.getBookVolumesLoader);
  const getSingleBookLoader = useSelector(e => e.Book.getSingleBookLoader);

  const [bookData, setBookData] = useState({});
  const [volumeArr, setVolumeArr] = useState([]);
  const [allInOneVolumeData, setAllInOneVolumeData] = useState(false);
  const [DownloadHover, setDownloadHover] = useState(false);
  let [downloadLoader, setDownloadLoader] = useState(false);

  useEffect(() => {
    const url = new URL(window.location);
    const bookId = url.searchParams.get('bookId');
    if (bookId) {
      dispatch(getBookVolumesAction(bookId));
      dispatch(getSingleBookAction(bookId));
    }
  }, []);

  useEffect(() => {
    if (getSingleBook) {
      setBookData(getSingleBook);
    }
  }, [getSingleBook]);

  useEffect(() => {
    if (volumesData) {
      const allVolume = volumesData.filter(obj => obj.all_in_one)[0];
      setAllInOneVolumeData(allVolume);
      const volume = volumesData.filter(obj => !obj.all_in_one);
      setVolumeArr(volume);
    }
  }, [volumesData]);

  return (
    <div className="relative h-min-100">
      <Header page={0} limit={2} />
      {getBookVolumesLoader && getSingleBookLoader ? (
        <Spinner />
      ) : (
        <>
          <div className="container md:px-0 flex-col md:flex-row mx-auto p-4 mt-10 flex" dir="rtl">
            <img
              className="cursor-pointer w-[100%] md:w-[255px]"
              src={bookData.cover_img_url ? bookData.cover_img_url : `${assetURL}${bookData.cover_img}`}
              // style={{ width: "100%" }}
            />
            <div className="mr-1 md:mr-1">
              <p className="text-4xl font-bold mt-4 md:mt-10">{bookData.bookName}</p>
              {/* <p className="text-[#7B867C] text-2xl mt-2">٤ فصل های کتاب</p> */}
              <button
                className="bg-[#fff] border-[#0E3A1E] border-[1px] disabled:opacity-[0.4] mt-[8px] disabled:cursor-not-allowed text-[#0E3A1E] flex items-center justify-center px-12 h-[48px] rounded-xl hover:bg-[#0E3A1E] hover:text-[#fff] ease-in duration-300 font-bold"
                onClick={() => navigate(`/categories?bookId=${bookData._id}`)}>
                {/* <PlusIcon className="mr-2" /> */}
                باز کن
              </button>
              {allInOneVolumeData ? (
                <>
                  <button
                    disabled={downloadLoader}
                    onMouseOver={() => setDownloadHover('active')}
                    onMouseOut={() => setDownloadHover(false)}
                    className="relative hover:bg-[#0E3A1E] hover:text-white text-xl border-green-900 px-8 py-2 bg-green-50 rounded-full my-2 flex items-center justify-center font-bold"
                    onClick={() => {
                      downloadFile(
                        allInOneVolumeData.pdf_file_url
                          ? allInOneVolumeData.pdf_file_url
                          : `${assetURL}${allInOneVolumeData.pdf_file}`,
                        `${bookData.bookName}-${allInOneVolumeData.volume_name}`,
                        'application/pdf',
                        setDownloadLoader
                      );
                    }}>
                    {downloadLoader ? <ButtonSpinner color={'#0E3A1E'} /> : null}
                    <span
                      className={
                        downloadLoader
                          ? `opacity-0 flex items-center justify-center`
                          : 'flex items-center justify-center'
                      }>
                      {DownloadHover === 'active' && <Tooltip text={'دانلود'} position={{ left: 'auto' }} />}
                      {DownloadHover === 'active' ? (
                        <img src={DownloadWhite} width={'20px'} className="ml-[10px]" />
                      ) : (
                        // <img src={Download} width={'24px'} />
                        <svg
                          width="20"
                          height="20"
                          viewBox="0 0 20 20"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                          className="ml-[10px]">
                          <path
                            d="M15 7.50008H13.3333C12.8333 7.50008 12.5 7.83341 12.5 8.33341C12.5 8.83341 12.8333 9.16675 13.3333 9.16675H15C15.5 9.16675 15.8333 9.50008 15.8333 10.0001V15.8334C15.8333 16.3334 15.5 16.6667 15 16.6667H5C4.5 16.6667 4.16667 16.3334 4.16667 15.8334V10.0001C4.16667 9.50008 4.5 9.16675 5 9.16675H6.66667C7.16667 9.16675 7.5 8.83341 7.5 8.33341C7.5 7.83341 7.16667 7.50008 6.66667 7.50008H5C3.58333 7.50008 2.5 8.58341 2.5 10.0001V15.8334C2.5 17.2501 3.58333 18.3334 5 18.3334H15C16.4167 18.3334 17.5 17.2501 17.5 15.8334V10.0001C17.5 8.58341 16.4167 7.50008 15 7.50008ZM6.91667 12.2501L9.41667 14.7501C9.58333 14.9167 9.75 15.0001 10 15.0001C10.25 15.0001 10.4167 14.9167 10.5833 14.7501L13.0833 12.2501C13.4167 11.9167 13.4167 11.4167 13.0833 11.0834C12.75 10.7501 12.25 10.7501 11.9167 11.0834L10.8333 12.1667V2.50008C10.8333 2.00008 10.5 1.66675 10 1.66675C9.5 1.66675 9.16667 2.00008 9.16667 2.50008V12.1667L8.08333 11.0834C7.75 10.7501 7.25 10.7501 6.91667 11.0834C6.58333 11.4167 6.58333 11.9167 6.91667 12.2501Z"
                            fill="#2A9134"
                          />
                        </svg>
                      )}
                      دانلود كتاب{' '}
                    </span>
                  </button>
                  <p className="text-[#7B867C] text-xl mb-2">دانلود تمام جلدها</p>
                </>
              ) : null}
            </div>
          </div>
          <div className="container mx-auto p-4 pt-0 md:px-0" dir="rtl">
            <div className="w-full flex justify-between bg-[#F5F5F5] px-4 pr-8 md:px-8  py-4 rounded-md mb-2" dir="rtl">
              <p className="text-xl font-bold">جلدها</p>
              <p className="w-36 md:w-48 text-xl font-bold">فايل</p>
            </div>
            {volumeArr.map((val, i) => {
              return !val.all_in_one ? <Volume val={val} i={i} bookData={bookData} /> : null;
            })}
          </div>
        </>
      )}

      <ScrollToTop />
      <Footer />
    </div>
  );
}

export default SingleBook;
