import { assetURL } from '../api/utils';

export const generateCharRange = (start, end) => {
  let result = '';
  for (let i = start; i <= end; i++) {
    result += String.fromCharCode(i);
  }
  return result;
};

export const escapeRegExp = str => str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');

export const swapNumbersInString = value =>
  value.replace(/([١-٩۱-۹]+)\/([١-٩۱-۹]+)/g, (match, num1, num2) => `${num2}/${num1}`);

/** Recives a url or file key if development */
export const getAssetUrl = url => {
  if (url.startsWith('http') || url.startsWith('https')) return url;

  return `${assetURL}${url}`;
};
