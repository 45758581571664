import axios from 'axios';
import SearchHadith from '../../features/hadith/pages/SearchHadith';
import { printElement } from '../printPage';
import download from 'downloadjs';
import { generateCharRange, escapeRegExp } from '../../util';
import { ARABIC_DIACRITIC_START_CODE, ARABIC_DIACRITIC_END_CODE } from '../../constants';

export const GET_ALL_POST_LOADER = 'GET_ALL_POST_LOADER';
export const getAllPostLoader = payload => ({
  type: 'GET_ALL_POST_LOADER',
  payload: payload,
});

export const getAllPostAction = status => {
  return async dispatch => {
    dispatch(getAllPostLoader(true));

    let config = {
      method: 'get',
      maxBodyLength: Infinity,
      url: `/api/v1/admin/allPost/get?status=${status}`,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    };

    axios(config)
      .then(response => {
        if (status === 'post') {
          dispatch(getAllPostData(response.data.data));
        }
        dispatch(getAllPostLoader(false));
      })
      .catch(error => {
        dispatch(getAllPostLoader(false));
      });
  };
};

export const ALL_POST_DATA_DATA = 'ALL_POST_DATA_DATA';
export const getAllPostData = payload => ({
  type: 'ALL_POST_DATA_DATA',
  payload: payload,
});

export const GET_POST_LOADER = 'GET_POST_LOADER';
export const getPostLoader = bool => ({
  type: 'GET_POST_LOADER',
  payload: bool,
});

export const getPostAction = (bookId, parentCat, categorie, sub_categorie, addsub_categorie, page, limit) => {
  return async dispatch => {
    dispatch(getPostLoader(true));

    let config = {
      method: 'get',
      maxBodyLength: Infinity,
      url: `/api/v1/admin/allPost/getPostWithoutVolume?${bookId ? `book=${bookId}&` : ''}${
        parentCat ? `main_categorie=${parentCat}&` : ''
      }${categorie ? `categorie=${categorie}&` : ''}${sub_categorie ? `sub_categorie=${sub_categorie}&` : ''}${
        addsub_categorie ? `addsub_categorie=${addsub_categorie}&` : ''
      }${page ? `page=${page}&` : ''}${limit ? `limit=${limit}` : ''}`,
      headers: {},
    };

    axios(config)
      .then(response => {
        dispatch(createPostData(response.data.data));
        dispatch(createPostCount(response.data.count));
        dispatch(getPostLoader(false));
      })
      .catch(error => {
        dispatch(getPostLoader(false));
      });
  };
};

export const POST_DATA_DATA = 'POST_DATA_DATA';
export const createPostData = payload => ({
  type: 'POST_DATA_DATA',
  payload: payload,
});

export const POST_DATA_COUNT = 'POST_DATA_COUNT';
export const createPostCount = payload => ({
  type: 'POST_DATA_COUNT',
  payload: payload,
});

export const GET_SINGLE_POST_LOADER = 'GET_SINGLE_POST_LOADER';
export const getSinglePostLoader = payload => ({
  type: 'GET_SINGLE_POST_LOADER',
  payload: payload,
});

export const getSinglePostAction = id => {
  return async dispatch => {
    dispatch(getSinglePostLoader(true));

    let config = {
      method: 'get',
      maxBodyLength: Infinity,
      url: `/api/v1/admin/allPost/getForWeb/${id}`,
      headers: {},
    };

    axios(config)
      .then(response => {
        dispatch(getSinglePostData(response.data.data));
        dispatch(
          getSinglePostNavigate({
            next: response.data.next,
            previous: response.data.previous,
          })
        );
        dispatch(getSinglePostLoader(false));
      })
      .catch(error => {
        dispatch(getSinglePostLoader(false));
      });
  };
};

export const SINGLE_POST_DATA_DATA = 'SINGLE_POST_DATA_DATA';
export const getSinglePostData = payload => ({
  type: 'SINGLE_POST_DATA_DATA',
  payload: payload,
});

export const SINGLE_POST_DATA_NAVIAGTE = 'SINGLE_POST_DATA_NAVIAGTE';
export const getSinglePostNavigate = payload => ({
  type: 'SINGLE_POST_DATA_NAVIAGTE',
  payload: payload,
});

export const GET_LATEST_NEWS_LOADER = 'GET_LATEST_NEWS_LOADER';
export const getLatestNewsLoader = payload => ({
  type: 'GET_LATEST_NEWS_LOADER',
  payload: payload,
});

export const getLatestNewsAction = () => {
  return async dispatch => {
    dispatch(getLatestNewsLoader(true));

    let config = {
      method: 'get',
      maxBodyLength: Infinity,
      url: `/api/v1/admin/newPosts/get`,
      headers: {},
    };

    axios(config)
      .then(response => {
        dispatch(getLatestNewsData(response.data.data));
        dispatch(getLatestNewsLoader(false));
      })
      .catch(error => {
        dispatch(getLatestNewsLoader(false));
      });
  };
};

export const GET_LATEST_NEWS_DATA = 'GET_LATEST_NEWS_DATA';
export const getLatestNewsData = payload => ({
  type: 'GET_LATEST_NEWS_DATA',
  payload: payload,
});

export const SEARCH_POST_LOADER = 'SEARCH_POST_LOADER';
export const searchPostLoader = payload => ({
  type: 'SEARCH_POST_LOADER',
  payload: payload,
});

const arabicDiacritics = generateCharRange(ARABIC_DIACRITIC_START_CODE, ARABIC_DIACRITIC_END_CODE);
export const searchPostAction = (text, page, limit, navigationFunc) => {
  return async dispatch => {
    dispatch(searchPostLoader(true));

    let config = {
      method: 'get',
      maxBodyLength: Infinity,
      url: `/api/v1/admin/allPost/postsearchForWeb?text=${text}&page=${page}&limit=${limit}`,
      headers: {},
    };

    let regex = '';

    try {
      regex = new RegExp(
        `${text
          .split('')
          .map(char => `${char}[${arabicDiacritics}]*`)
          .join('')}`,
        'i'
      );
    } catch (error) {
      console.log(regex);
      regex = new RegExp(escapeRegExp(text), 'i');
    }

    const maxLength = 400;

    axios(config)
      .then(response => {
        response.data.data = response.data.data.map(post => {
          let match;
          let matchIndex = -1;
          while ((match = regex.exec(post.preview)) !== null) {
            matchIndex = match.index;

            break;
          }

          const startIndex = Math.max(0, matchIndex - maxLength / 2);
          const endIndex = startIndex + maxLength;

          const startEllipsis = startIndex > 0 ? '...' : '';
          const endEllipsis = endIndex < post.preview.length ? '...' : '';
          const preview =
            startEllipsis +
            post.preview
              .slice(startIndex, endIndex)
              .replace(regex, match => `<span class="bg-green-500">${match}</span>`) +
            endEllipsis;

          const reference = post.reference.replace(regex, match => `<span class="bg-green-500">${match}</span>`);
          const post_number = post.post_number.replace(regex, match => `<span class="bg-green-500">${match}</span>`);

          return {
            ...post,
            preview,
            reference,
            post_number,
          };
        });

        dispatch(searchPostData(response.data.data));
        dispatch(searchPostDataCount(response.data.count));
        if (navigationFunc) {
          navigationFunc();
        }
        dispatch(searchPostLoader(false));
      })
      .catch(error => {
        console.log(error);
        dispatch(searchPostLoader(false));
      });
  };
};
export const GET_POST_SEARCH_DATA_COUNT = 'GET_POST_SEARCH_DATA_COUNT';
export const searchPostDataCount = payload => ({
  type: 'GET_POST_SEARCH_DATA_COUNT',
  payload: payload,
});

export const GET_POST_SEARCH_DATA = 'GET_POST_SEARCH_DATA';
export const searchPostData = payload => ({
  type: 'GET_POST_SEARCH_DATA',
  payload: payload,
});

export const downloadPost = (objParam, setLoader) => {
  return async dispatch => {
    setLoader(true);
    console.log(objParam);
    let obj = {
      name: `${objParam.book[0].bookName}-${objParam.post_number}`,
      html: printElement(objParam),
      bookName: objParam.book[0].bookName,
    };

    let data = JSON.stringify(obj);

    let config = {
      method: 'post',
      url: '/api/v1/admin/allPost/DownloadHaidth',
      headers: {
        'Content-Type': 'application/json',
      },
      responseType: 'blob',
      data: data,
    };

    axios(config)
      .then(response => {
        const blob = new Blob([response.data], { type: 'application/pdf' });
        download(blob, obj.name, 'application/pdf');
        setLoader(false);
      })
      .catch(error => {
        setLoader(false);
      });
  };
};
