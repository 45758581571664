import React, { useEffect } from 'react';
import Header from '../../../constant/Header';
import Footer from '../../../constant/footer';
import { useNavigate } from 'react-router';
import ScrollToTop from '../../../constant/ScrollToTop';
import { useDispatch, useSelector } from 'react-redux';
import { getAllBookAction } from '../../../module/action/Book';
import Spinner from '../../../constant/Spinner';
import { assetURL } from '../../../api/utils';
function Books() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const bookData = useSelector(e => e.Book.bookData);
  const getAllBookLoader = useSelector(e => e.Book.getAllBookLoader);

  useEffect(() => {
    dispatch(getAllBookAction());
  }, []);

  return (
    <div className="relative h-min-100">
      <Header page={0} limit={2} />
      <div className="container mx-auto p-4 pt-0 md:px-0 mt-10 flex justify-between">
        <p className="underline underline-offset-8 text-base font-bold"></p>
        <div>
          <p className=" hidden md:block text-themeColor text-right text-4xl font-bold">كتاب ها</p>
        </div>
      </div>
      <div className="container mx-auto p-8 pt-0 md:px-0 flex flex-wrap" dir="rtl">
        {getAllBookLoader ? (
          <Spinner />
        ) : (
          bookData.map((val, i) =>
            val.status === 'approved' ? (
              <div
                dir="rtl"
                key={i}
                onClick={() => navigate(`/SingleBook?bookId=${val._id}`)}
                className="cursor-pointer md:ml-8 ml-0 mb-10 w-96 md:w-[255px]">
                <img
                  src={val.cover_img_url ? val.cover_img_url : `${assetURL}${val.cover_img}`}
                  className=" h-auto w-full  md:h-[359px] border-[1px] border-[#7B867C] rounded-[12px]"
                />
                <p className="text-4xl font-bold mt-4">{val.bookName}</p>
              </div>
            ) : null
          )
        )}
      </div>
      <ScrollToTop />
      <Footer />
    </div>
  );
}

export default Books;
