import React, { useEffect, useState } from 'react';

import Header from '../../../../constant/Header';
import Footer from '../../../../constant/footer';
import ScrollToTop from '../../../../constant/ScrollToTop';
import { useDispatch, useSelector } from 'react-redux';
import { getSinglePostAction } from '../../../../module/action/Post';
import { catTree } from '../../../../module/ConstantFunctions';
import { useNavigate } from 'react-router';
import Spinner from '../../../../constant/Spinner';
import SinlgeHadith from '../../components/SinlgeHadith/SinlgeHadith';

function HadithDetail() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const postDetail = useSelector(e => e.Post.singlePost) || {};
  const anwanNavigate = useSelector(e => e.Post.anwanNavigate);
  const singlePostLoader = useSelector(e => e.Post.singlePostLoader);

  const [next, setNext] = useState('');
  const [previous, setPrevious] = useState('');

  const url = new URL(window.location);
  const post = url.searchParams.get('postId');

  useEffect(() => {
    dispatch(getSinglePostAction(post));
  }, []);

  let tree;
  let activeAnwan;
  if (!singlePostLoader) ({ activeAnwan, tree } = catTree(postDetail));

  const singleUpdate = postid => dispatch(getSinglePostAction(postid));

  useEffect(() => {
    singleUpdate(post);
  }, [post]);

  useEffect(() => {
    setPrevious(anwanNavigate.previous);
    setNext(anwanNavigate.next);
  }, [anwanNavigate]);

  return (
    <div className="relative h-min-100">
      <Header page={0} limit={2} />
      {singlePostLoader ? (
        <Spinner />
      ) : (
        <SinlgeHadith
          navFunction={singleUpdate}
          next={next?._id}
          nextFunction={() => {
            singleUpdate(next?._id);
            navigate(`/HadithDetail?postNumber=${next?.post_number?.trim()}&postId=${next?._id}`);
          }}
          previous={previous?._id}
          previousFunction={() => {
            singleUpdate(previous?._id);
            navigate(`/HadithDetail?postNumber=${previous?.post_number?.trim()}&postId=${previous?._id}`);
          }}
          activeAnwan={activeAnwan}
          postDetail={postDetail}
          showCatTree={true}
        />
      )}

      <ScrollToTop />
      <Footer />
    </div>
  );
}

export default HadithDetail;

// font-family: 'IBM Plex Sans Arabic', sans-serif;
