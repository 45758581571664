import React, { useEffect, useState } from 'react';
import Header from '../../../constant/Header';
import Footer from '../../../constant/footer';
import HadithPreviewCard from '../../hadith/components/HadithPreviewCard';
import { useNavigate } from 'react-router';
import ScrollToTop from '../../../constant/ScrollToTop';
import { useDispatch, useSelector } from 'react-redux';
import Pagination from '../../../constant/pagination';
import { getCategoriesPostAction, getCategoryAction } from '../../../module/action/Category';
import { getPostAction } from '../../../module/action/Post';
import Spinner from '../../../constant/Spinner';
import { Dropdown, Space } from 'antd';
import ClickablePath from '../../../constant/ClickablePath';

const POSTS_LIMIT = 4;

function Chapters() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const anwanPostData = useSelector(e => e.Post.anwanPostData);
  const anwanPostCount = useSelector(e => e.Post.anwanPostCount);
  const getPostLoader = useSelector(e => e.Post.getPostLoader);
  const getCategoriesPostData = useSelector(e => e.Post.getCategoriesPostData);
  const mainCategories = useSelector(e => e.Category.CategoryData) || [];
  const subcategories = useSelector(e => e.Category.subCategoryData) || [];

  const [page, setPage] = useState(0);
  const [data, setData] = useState([]);

  const url = new URL(window.location);
  const bookId = url.searchParams.get('bookId');
  const mainCategoryId = url.searchParams.get('mainCategory');
  const categoryId = url.searchParams.get('category');
  const subcategoryId = url.searchParams.get('subCategory');
  const addSubCategoryId = url.searchParams.get('addSub_category');

  const mainCategoryName = mainCategories.find(category => category._id === mainCategoryId)?.category_name;
  const categoryName = subcategories.find(subcategory => subcategory._id === categoryId)?.category_name;
  const subcategoryName = subcategories.find(subcategory => subcategory._id === subcategoryId)?.category_name;
  const addSubcategoryName = subcategories.find(subcategory => subcategory._id === addSubCategoryId)?.category_name;

  const getData = (page, limit) => {
    let activeRef = addSubCategoryId || subcategoryId || categoryId || mainCategoryId;

    dispatch(getCategoryAction(bookId, 'Main'));
    dispatch(getCategoryAction(bookId, 'Sub'));
    dispatch(getPostAction(bookId, mainCategoryId, categoryId, subcategoryId, addSubCategoryId, page, limit));
    dispatch(getCategoriesPostAction(bookId, activeRef));
  };

  const activeAnwan = [mainCategoryName, categoryName, subcategoryName, addSubcategoryName].filter(Boolean).at(-1);

  useEffect(() => {
    getData(page, POSTS_LIMIT);
  }, [page, bookId, mainCategoryId, categoryId, subcategoryId, addSubCategoryId]);

  useEffect(() => {
    if (anwanPostData) {
      setData(anwanPostData);
    }
  }, [anwanPostData]);

  let items = [];

  if (getCategoriesPostData && getCategoriesPostData.length) {
    let arr = getCategoriesPostData.map((a, i) => {
      return {
        ...a,
        label: (
          <div
            onClick={() => {
              navigate(
                `/Chapters?bookId=${bookId}${
                  subcategoryName
                    ? `&mainCategory=${mainCategoryId}&category=${categoryId}&subCategory=${subcategoryId}&addSub_category=${a._id}`
                    : categoryName
                    ? `&mainCategory=${mainCategoryId}&category=${categoryId}&subCategory=${a._id}`
                    : mainCategories
                    ? `&mainCategory=${mainCategoryId}&category=${a._id}`
                    : '#'
                }`
              );
            }}
            className="text-themeColorDark text-right text-lg font-bold mb-2">
            {a.category_name}
          </div>
        ),
        key: i,
      };
    });
    items = arr;
  }

  return (
    <div className="relative h-min-100">
      <Header page={0} limit={2} />
      <div className="container mx-auto py-8 px-4 md:px-0 md:mt-10">
        <div className="mb-10 flex justify-end">
          <div>
            <p className="text-themeColorDark text-right text-xl font-bold mb-2">
              &nbsp;
              {getCategoriesPostData && getCategoriesPostData.length ? (
                <Dropdown
                  menu={{
                    items,
                  }}
                  placement="bottomRight"
                  trigger={['click']}>
                  <a onClick={e => e.preventDefault()} className="align-middle">
                    <Space className="h-[30px]">
                      <svg
                        stroke="currentColor"
                        fill="currentColor"
                        stroke-width="0"
                        viewBox="0 0 16 16"
                        height="15px"
                        width="15px"
                        xmlns="http://www.w3.org/2000/svg">
                        <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"></path>
                      </svg>
                    </Space>
                  </a>
                </Dropdown>
              ) : null}
              &nbsp;{' '}
              <span
                onClick={() => {
                  navigate(`/categories?bookId=${bookId}`);
                }}
                style={{ cursor: 'pointer' }}>
                فهرست
              </span>
              <ClickablePath
                bookId={bookId}
                mainCategoryId={mainCategoryId}
                categoryId={categoryId}
                subcategoryId={subcategoryId}
                addSubCategoryId={addSubCategoryId}
                mainCategoryName={mainCategoryName}
                categoryName={categoryName}
                subcategoryName={subcategoryName}
                addSubcategoryName={addSubcategoryName}
              />
            </p>
            <p className="text-themeColor text-right text-4xl font-bold">{activeAnwan}</p>
          </div>
        </div>
        {/* list */}
        {getPostLoader ? (
          <Spinner />
        ) : data.length ? (
          data.map((a, i) => (
            <HadithPreviewCard
              shareUrl={
                'https://mail.google.com/mail/u/0/?tf=cm' +
                '&body=' +
                `https%3A%2F%2Feastgaheazadi.com%2FHadithDetail%3FpostNumber%3D${a.post_number?.trim()}%26postId%3D${
                  a._id
                }`
              }
              data={a}
              index={i}
              chapter={'chapter'}
            />
          ))
        ) : (
          <div className="flex justify-center flex-col items-center">
            <p className="mb-10 flex justify-end text-themeColorDark text-right text-3xl font-bold mb-2">
              هیچ حدیثی در این دسته وجود ندارد، دسته های دیگر را بررسی کنید
            </p>
          </div>
        )}
        <Pagination page={page} setPage={setPage} limit={POSTS_LIMIT} count={anwanPostCount} />
      </div>

      <ScrollToTop />
      <Footer />
    </div>
  );
}

export default Chapters;
